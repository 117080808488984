import {
  Chip,
  Fab,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

export const TagSelector = ({ createTag, getAllTags, selectedTags, selectTags, createEnabled, readonly }) => {
  const [newTag, setNewTag] = useState("")
  const [allTags, setAllTags] = useState([])
  const [filteredTags, setFilteredTags] = useState([])
  const [existingTag, setExistingTag] = useState(false)

  async function fetchTags() {
    const tags = await getAllTags()

    setFilteredTags(tags)
    setAllTags(tags)
  }

  useEffect(() => {
    fetchTags()
  }, [])

  useEffect(() => {
    const filteredTags = allTags.filter((tag) =>
      tag.invariantName.includes(newTag.split(" ").join("").toLocaleLowerCase())
    )

    const existingTag = !!allTags.find((tag) => tag.invariantName === newTag.split(" ").join("").toLocaleLowerCase())

    setExistingTag(existingTag)
    setFilteredTags(filteredTags)
  }, [newTag])

  function tagSelected(tag, isSelected) {
    const newTags = selectedTags.filter((st) => tag.id !== st)

    if (isSelected) {
      newTags.push(tag.id)
    }

    selectTags(newTags)
  }

  const allowCreate = !readonly && createEnabled

  return (
    <Grid container spacing={2}>
      {allowCreate ? (
        <Grid item xs={12}>
          <form
            onSubmit={async (event) => {
              event.preventDefault()

              await createTag({ name: newTag })

              setNewTag("")

              await fetchTags()
            }}
          >
            <FormGroup row={true}>
              <FormControl margin="dense">
                <TextField
                  variant="outlined"
                  label="Tag Name"
                  value={newTag}
                  onChange={(event) => setNewTag(event.target.value)}
                />
                <FormHelperText>Enter a tag name to filter tags or create a new tag</FormHelperText>
              </FormControl>
              <FormControl margin="normal" style={{ paddingLeft: 10 }}>
                <Tooltip title={<Typography>{"Create"}</Typography>}>
                  <Fab disabled={existingTag} color="secondary" type="submit" variant="extended">
                    {"Create Tag"}
                  </Fab>
                </Tooltip>
              </FormControl>
            </FormGroup>
          </form>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        {filteredTags.map((tag) => {
          const selected = !!selectedTags.find((st) => tag.id === st)

          if (readonly && !selected) {
            return null
          }

          return (
            <Chip
              style={{ marginRight: 10 }}
              key={tag.id}
              label={tag.name}
              color={selected ? "primary" : undefined}
              onClick={() => {
                if (readonly) {
                  return
                }

                tagSelected(tag, !selected)
              }}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}
