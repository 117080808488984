import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { TagSelector as ReactTagSelector } from "../React/TagSelector"
import React from "react"

export default class TagSelector extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["tag-target", "create-enabled", "readonly"]
  }

  constructor() {
    super()

    this.selectedTags = []

    this.jsxRootComponent = () => (
      <ReactTagSelector
        readonly={this.hasAttribute("readonly")}
        createEnabled={this.hasAttribute("create-enabled")}
        createTag={(tag) => this.createTag(tag)}
        getAllTags={() => this.getAllTags()}
        searchTags={(tag) => this.searchTags(tag)}
        selectedTags={this.selectedTags}
        selectTags={(tags) => this.selectTags(tags)}
      />
    )
  }

  async createTag(tag) {
    try {
      const token = await window.authorise()

      const result = await fetch(`${this.getAttribute("tag-target")}`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(tag),
      })

      if (!result.ok) {
        this.parseError(result, "Failed to create Tag")
      }

      this.onSuccess && this.onSuccess("Tag created")
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  async searchTags(tag) {
    try {
      const token = await window.authorise()

      const result = await fetch(`${this.getAttribute("tag-target")}/search`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(tag),
      })

      if (!result.ok) {
        this.parseError(result, "Failed to search Tags")
      }

      return await result.json()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  async getAllTags() {
    try {
      const token = await window.authorise()

      const result = await fetch(`${this.getAttribute("tag-target")}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      })

      if (!result.ok) {
        this.parseError(result, "Failed to get Tags")
      }

      return await result.json()
    } catch (error) {
      this.onError && this.onError(error)
    }
  }

  selectTags(tags) {
    this.dispatchEvent(new CustomEvent("tags-selected", { composed: true, bubbles: true, detail: tags }))
  }

  setSelectedTags(tags) {
    this.selectedTags = tags
    this.render()
  }
}

customElements.define("tag-selector", TagSelector)
